body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F121C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#menu {
  position: sticky;
  top:0;
  left:0;
  background-color: #707582;
  min-height: 48px;
  z-index: 99;
  width: 100vw;
  background-color: rgb(62, 121, 62);
}

#menu-div {
  height: 48px;
}

#menu-contents{
  position:sticky;
  height: 450px;
  top:0;
  left:0;
}

#menu li {
  list-style-type: none;
  font-size: 20px;
}

#menu a {
  font-size: 20px;
  text-decoration: none; 
  color: black;
}

#menu a:hover {
  font-size: 20px;
  text-decoration: none; 
  color: white;
}

.shimmer {
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

footer {
  color: white;
}

.roundedcorners {
  border-radius: 25px;
}