@media screen and (min-width: 800px) {
    .pickle-div {
        margin: 30px 0 30px 100px;
        position: relative;
        float: left;
    }
}


@media screen and (max-width: 801px) {
    .pickle-div {
        margin: auto;
    }
}

.pickle-div {
    height: 600px;
    width: 300px;
}

.pickle-image-holder {
    width: 100%;
    height: 260px;
}

.pickle-image {
    object-fit: contain;
    width: 100%;
    border-radius: 25px;
}